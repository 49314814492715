const types = [
  {value:2,label:"Pembelian"},
  {value:1,label:"Transfer In"},
]
const statuses = [
  {value:"",label:"Semua Status"},
  {value:"Permintaan",label:"Permintaan"},
  {value:"Disetujui Pusat",label:"Disetujui Pusat"},
  {value:"Ditolak Pusat",label:"Ditolak Pusat"},
]
const detailDefault = {
  id_items: null,
  item_name: "",
  qty: 0,
  average: 0,
  balance: 0,
  id_unit: null,
  name_unit: "",
  note: ""
}

export default {
  types,
  statuses,
  detailDefault
}