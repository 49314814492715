<template>
  <form-validation @submit="submitForm">
    <b-row>
      <b-col sm="12" md="8">
        <my-card :isloading="cardloading" :title="code">
          <template slot="body">
            <b-row>
              <b-col sm="12">
                <form-select :filter-id="idBranchArray" ref="branchSelect" url="v1/branch_select2" @value-changed="branch_name = $event.label;fetchStockDetail" :rules="{required: true}" v-model="id_branch" label="Cabang Asal"></form-select>
              </b-col>
              <b-col sm="12">
                <b-form-group label-for="radio-group-type">
                  <b-form-radio-group
                  :disabled="formStatus!=0"
                  id="radio-group-type"
                  v-model="type"
                  :options="utils.types"
                  text-field="label"
                  >
                  </b-form-radio-group>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="8">
                <form-date @input="fetchGroup" :disabled="!cP(82)||[2,3].includes(formStatus)" :rules="{required: true}" v-model="date" label="Tanggal"></form-date>
              </b-col>
              <b-col sm="12" md="8">
                <form-date @input="fetchGroup" :disabled="[2,3].includes(formStatus)" :rules="{required: true}" v-model="date_of_need" label="Tanggal Kebutuhan"></form-date>
              </b-col>
              <b-col sm="12" v-show="type==1">
                <form-select :disabled="formStatus!=0" :queryparams="{id_regional: mainRegionalId}" ref="branch2Select" url="v1/branch_select2" @value-changed="branch_sender_name = $event.label" :rules="{required: type==1}" v-model="id_branch_sender" label="Cabang Tujuan"></form-select>
              </b-col>
              <b-col sm="12" v-show="type==2">
                <form-select :disabled="formStatus!=0" ref="supplierSelect" url="v1/supplier_select2" @value-changed="supplier_name = $event.label" :rules="{required: type==2}" v-model="id_supplier" label="Pemasok"></form-select>
              </b-col>
              <b-col sm="12">
                <form-textarea :disabled="[2,3].includes(formStatus)" :rules="{required: false}" v-model="note" label="Catatan"></form-textarea>
              </b-col>
              <b-col sm="12" v-if="formStatus==2">
                <form-textarea :rules="{required: false}" v-model="note_main_branch" label="Catatan Pusat"></form-textarea>
              </b-col>
            </b-row>
          </template>
        </my-card>
      </b-col>
      <b-col v-if="$route.params.id" sm="12" md="4">
        <my-card title="Informasi User">
          <template slot="body">
            <table class="w-100">
              <tbody>
                <tr>
                  <td class="font-weight-bold">Di Input Oleh :</td>
                  <td class="text-right">{{inputInfo.user_create}}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">Di Update Oleh :</td>
                  <td class="text-right">{{inputInfo.user_update}}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">Di Approve Oleh :</td>
                  <td class="text-right">{{inputInfo.user_approval}}</td>
                </tr>
              </tbody>
            </table>
          </template>
        </my-card>
      </b-col>
      <b-col sm="12">
        <my-card :isloading="cardloading" title="Detail Item">
          <template slot="body">
            <div v-if="formStatus!=2">
              <b-row v-for="(tr,i) in details" :key="i">
                <b-col sm="12" md="3">
                  <form-select size="sm" :disabled="formStatus==3" :ref="`itemSelect-${i}`" url="v1/item_select2" @value-changed="tr.item_name = $event.label;tr.id_unit=null" :rules="{required: true}" v-model="tr.id_items" :label="`Item ke ${i+1}`"></form-select>
                </b-col>
                <b-col sm="6" md="1">
                  <form-input-number size="sm" disabled v-model="tr.average" label="Average"></form-input-number>
                </b-col>
                <b-col sm="6" md="1">
                  <form-input-number size="sm" disabled v-model="tr.balance" label="Balance"></form-input-number>
                </b-col>
                <b-col sm="6" md="1">
                  <form-input-number size="sm" disabled v-model="tr.qty_suggest" label="Qty Ref"></form-input-number>
                </b-col>
                <b-col sm="6" md="1">
                  <form-input-number size="sm" :disabled="!cP(140)" :rules="{ required: true }" v-model="tr.qty" label="Qty Permintaan"></form-input-number>
                  <!-- <form-input-number size="sm" :rules="{ required: true }" v-model="tr.qty" label="Qty Permintaan"></form-input-number> -->
                </b-col>
                <b-col sm="6" md="2">
                  <form-select size="sm" :disabled="formStatus==3" :ref="`unitSelect-${i}`" url="v1/multi_unit_balance_select2" :queryparams="{id_item: tr.id_items, id_branch: id_branch, date: date, date_of_need: date_of_need}" @value-changed="unitChange($event,i)" :rules="{required: true}" v-model="tr.id_unit" label="Stn Permintaan"></form-select>
                </b-col>
                <b-col sm="12" md="2">
                  <form-input size="sm" :disabled="formStatus==3" v-model="tr.note" label="Catatan"></form-input>
                </b-col>
                <b-col>
                  <b-button size="sm" v-if="i>0||formStatus!=3" class="mt-2" block variant="danger" @click="deleteRow(i)">
                    <feather-icon icon="TrashIcon"></feather-icon> Hapus
                  </b-button>
                </b-col>
                <b-col sm="12">
                  <hr>
                </b-col>
              </b-row>
            </div>
            <div v-else>
              <b-row no-gutters v-for="(tr,i) in details" :key="i">
                <b-col sm="12" md="2">
                  <form-select size="sm" :disabled="tr.id_request_detail!=undefined||formStatus==3" :ref="`itemSelect-${i}`" url="v1/item_select2" @value-changed="tr.item_name = $event.label;tr.id_unit=null;tr.id_unit_approve=null" :rules="{required: true}" v-model="tr.id_items" :label="`Item ke ${i+1}`"></form-select>
                </b-col>
                <b-col sm="6" md="1" class="d-flex justify-content-between">
                  <form-input-number size="sm" disabled v-model="tr.average" label="Average"></form-input-number>
                  <form-input-number size="sm" disabled v-model="tr.balance" label="Balance"></form-input-number>
                </b-col>
                <b-col sm="6" md="1">
                  <form-input-number size="sm" disabled v-model="tr.qty_suggest" label="Qty Ref"></form-input-number>
                </b-col>
                <b-col sm="6" md="1">
                  <!-- <form-input-number :rules="{ required: true }" size="sm" v-model="tr.qty" label="Qty Permintaan"></form-input-number> -->
                  <form-input-number :rules="{ required: true }" size="sm" :disabled="!cP(140)" v-model="tr.qty" label="Qty Permintaan"></form-input-number>
                </b-col>
                <b-col sm="6" md="2">
                  <form-select size="sm" disabled :ref="`unitSelect-${i}`" url="v1/multi_unit_select2" :queryparams="{id_item: tr.id_items}" @value-changed="tr.name_unit = $event.label" :rules="{required: true}" v-model="tr.id_unit" label="Stn Permintaan"></form-select>
                </b-col>
                <b-col sm="6" md="1">
                  <form-input-number :disabled="formStatus==3" :rules="{ required: true }" size="sm" v-model="tr.qty_approve" label="Qty Persetujuan"></form-input-number>
                </b-col>
                <b-col sm="6" md="2">
                  <form-select :disabled="formStatus==3" size="sm" :ref="`unitSelectApprove-${i}`" url="v1/multi_unit_select2" :queryparams="{id_item: tr.id_items}" @value-changed="tr.name_unit_approve = $event.label" :rules="{required: true}" v-model="tr.id_unit_approve" label="Stn Persetujuan"></form-select>
                </b-col>
                <b-col sm="12" md="1">
                  <form-input :disabled="formStatus==3" size="sm" v-model="tr.note" label="Catatan"></form-input>
                </b-col>
                <b-col>
                  <b-button v-if="(i>0 && !tr.id_request_detail) || formStatus!=3" class="mt-2" block variant="danger" @click="deleteRow(i)" size="sm">
                    <feather-icon icon="TrashIcon"></feather-icon> Hapus
                  </b-button>
                </b-col>
                <b-col sm="12">
                  <hr>
                </b-col>
              </b-row>
            </div>
            <b-row>
              <b-col sm="12" md="2" class="mt-2" order-sm="1" order-md="0">
                <b-button v-if="formStatus!=3" block variant="primary" @click="addRow">
                  <feather-icon icon="PlusIcon"></feather-icon> Tambah Detail
                </b-button>
              </b-col>
              <b-col sm="12" offset-md="7" md="3">
                <form-input-number disabled :value="totalQty" label="Total" :rules="{required: true}"></form-input-number>
              </b-col>
              <b-col sm="12" offset-md="9" md="3">
                <form-input-number disabled :value="totalQtyApproval" label="Total Persetujuan" :rules="{required: true}"></form-input-number>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12" class="mt-4" v-if="formStatus!=3">
                <button-save v-if="formStatus!=2" :isloading="isloading"></button-save>
                <template v-if="formStatus==2">
                  <b-button variant="primary" @click="onClickApprove" class="mr-1">
                    <feather-icon icon="CheckSquareIcon"></feather-icon> Setujui
                  </b-button>
                  <b-button variant="danger" @click="onClickReject">
                    <feather-icon icon="XSquareIcon"></feather-icon> Tolak
                  </b-button>
                </template>
                <button-back class="ml-1" :to="{name:'stockins'}"></button-back>
              </b-col>
            </b-row>
          </template>
        </my-card>
      </b-col>
    </b-row>
  </form-validation>
</template>

<script>
import FormTextarea from '@/my-components/FormTextarea.vue'
import FormDate from '@/my-components/FormDate.vue'
import FormInputNumber from '@/my-components/FormInputNumber.vue'
import FormSelect from '@/my-components/FormSelect.vue'
import FormInput from '@/my-components/FormInput.vue'
import ButtonSave from '@/my-components/ButtonSave.vue'
import ButtonBack from '@/my-components/ButtonBack.vue'
import { mapActions } from 'vuex'
import FormValidation from '@/my-components/FormValidation.vue'
import {BFormGroup,BFormRadioGroup,BButton} from 'bootstrap-vue'
import utils from './utils'
import _ from 'lodash'

export default {
  components:{
    FormDate,
    FormInput,
    ButtonSave,
    ButtonBack,
    FormValidation,
    FormSelect,
    FormInputNumber,
    FormTextarea,
    BFormGroup,
    BFormRadioGroup,
    BButton
  },
  data(){
    return {
      utils:utils,
      isloading: false,
      cardloading: false,
      approvalButton: "Disetujui Pusat",
      id: this.$route.params.id||null,
      id_branch: null,
      code: "",
      branch_name: "",
      date: this.$moment().format(),
      date_of_need: this.$moment().format(),
      type: 2,
      name_type: "Pembelian",
      id_branch_sender: null,
      branch_sender_name: "",
      id_supplier: null,
      supplier_name: "",
      total_qty: 0,
      note: "",
      note_main_branch: "",
      details : [],
      inputInfo: {}
    }
  },
  computed:{
    totalQty(){
      let total = 0
      for (let i = 0; i < this.details.length; i++) {
        const e = this.details[i];
        total+=parseFloat(e.qty)
      }
      return total
    },
    totalQtyApproval(){
      let total = 0
      for (let i = 0; i < this.details.length; i++) {
        const e = this.details[i];
        total+=parseFloat(e.qty_approve||0)
      }
      return total
    },
    formStatus(){
      const meta = this.$route.meta
      if(meta.isEdit) return 1
      if(meta.isApprove) return 2
      if(meta.isShow) return 3
      else return 0
    }
  },
  methods:{
    ...mapActions({
      dispatchStore: 'stockins/store',
      dispatchShow: 'stockins/show',
      dispatchUpdate: 'stockins/update',
      dispatchApprove: 'stockins/approve',
    }),
    async getData(){
      this.cardloading = true
      if(this.id){
        const data = await this.dispatchShow(this.id)
        const {user_create,user_update,user_approval} = data
        this.inputInfo = {user_create,user_update,user_approval}
        this.code = data.code
        this.id_branch = data.id_branch
        this.branch_name = data.branch_name
        this.date = data.date
        this.date_of_need = data.date_of_need
        this.name_type = data.name_type
        this.type = data.type
        this.note = data.note
        this.id_supplier = data.id_supplier
        this.supplier_name = data.supplier_name
        this.id_branch_sender = data.id_branch_sender
        this.branch_sender_name = data.branch_sender_name
        if(this.formStatus==1) this.details = data.stock_in_request_details
        else {
          const approved = data.stock_in_request_approvals
          this.details = data.stock_in_request_details.map(d => {
            const qtyApproveFind = _.find(approved,{'id_items':d.id_items,'id_unit':d.id_unit})
            return Object.assign(d,{
              id_request_detail: d.id,
              qty_approve: qtyApproveFind ? qtyApproveFind.qty : d.qty,
              id_unit_approve: d.id_unit,
              name_unit_approve: d.name_unit,
              note:""
            })
          })
        }
        // let listIndex = []
        for (let x = 0; x < this.details.length; x++) {
          const data = await this.rowFindStockReal(x)
          this.details[x].balance = data.balance
          this.details[x].average = data.average
          this.details[x].qty_suggest = data.qty_suggest
        }
        // Promise.all()
        if(data.id_branch) this.$refs.branchSelect.initOption([{value:data.id_branch,label:data.branch_name}])
        if(data.id_supplier) this.$refs.supplierSelect.initOption([{value:data.id_supplier,label:data.supplier_name}])
        if(data.id_branch_sender) this.$refs.branch2Select.initOption([{value:data.id_branch_sender,label:data.branch_sender_name}])

        for (let i = 0; i < this.details.length; i++) {
          const e = this.details[i];
          this.$nextTick(() => {
            this.$refs[`itemSelect-${i}`][0].initOption([{value:e.id_items,label:e.item_name}])
            this.$refs[`unitSelect-${i}`][0].initOption([{value:e.id_unit,label:e.name_unit}])
            if(this.formStatus==2) this.$refs[`unitSelectApprove-${i}`][0].initOption([{value:e.id_unit,label:e.name_unit}])
          })
        }
      } else {
        const defaults = this.$store.state.auth
        this.$refs.branchSelect.initOption(defaults.defaultbranches)
        this.id_branch = defaults.profile.id_branch
        this.branch_name = defaults.profile.branch_name
        this.addRow()
      }

      this.cardloading = false
    },
    async submitForm(){
      this.isloading = true
      const {id,code,id_branch,branch_name,date,date_of_need,type,total_qty,note,details,id_branch_sender,branch_sender_name,id_supplier,supplier_name} = this
      let params = {id,code,id_branch,branch_name,date,date_of_need,type,total_qty,note,details,id_branch_sender,branch_sender_name,id_supplier,supplier_name}
      params.total_qty = this.totalQty
      if(this.type==1){
        params.id_supplier = null
        params.supplier_name = ""
        params.name_type = "Transfer In"
        if(id_branch==id_branch_sender) {
          this.isloading = false
          return this.toastError("Cabang Asal dan Tujuan tidak boleh sama!")
        }
      } else {
        params.id_branch_sender = null
        params.branch_sender_name = ""
        params.name_type = "Pembelian"
      }
      if(this.formStatus==2) {
        let details = JSON.parse( JSON.stringify(this.details) )
        details = details.map(d => {
          return Object.assign(d,{
            qty: d.qty_approve,
            id_unit: d.id_unit_approve,
            name_unit: d.name_unit_approve,
          })
        })
        params = {
          id: this.id,
          code: this.code,
          note_main_branch: this.note_main_branch,
          total_qty_approval: this.totalQtyApproval,
          status: this.approvalButton,
          details: details
        }
      }
      try {
        if(this.formStatus==0) await this.dispatchStore(params)
        else if(this.formStatus==1) await this.dispatchUpdate(params)
        else await this.dispatchApprove(params)
        this.toastSuccess("OK","Data berhasil disimpan!")
        this.$router.push({name:'stockins'})
      } catch (error) {
        this.handleError(error)
      }
      this.isloading = false
    },
    unitChange(e,row){
      this.details[row].name_unit = e.label
      this.details[row].balance = e.balance
      this.details[row].qty_suggest = e.qty_suggest
      this.details[row].qty = e.qty_suggest > 0 ? e.qty_suggest : 0
      this.details[row].average = e.moving_avg
    },
    async fetchStockDetail(){
      for (let i = 0; i < this.details.length; i++) {
        const data = await this.rowFindStockReal(i)
        this.details[i].balance = data.balance
        this.details[i].qty_suggest = data.qty_suggest
        this.details[i].average = data.average
        this.details[i].qty = data.qty_suggest > 0 ? data.qty_suggest : 0
      }
    },
    async rowFindStockReal(row){
      const tr = this.details[row]
      try {
        const {data} = await this.$http.get(`v1/multi_unit_balance_select2`,{params:{
          id_item: tr.id_items,
          id_branch: this.id_branch,
          name: tr.name_unit.substr(0,3),
          date: this.date,
          date_of_need: this.date_of_need,
        }})
        if(data.length>0){
          const finds = _.find(data,{'value': tr.id_unit})
          this.details[row].balance = finds.balance
          this.details[row].average = finds.moving_avg
          this.details[row].qty_suggest = finds.qty_suggest
          this.details[row].qty = finds.qty_suggest > 0 ? finds.qty_suggest : 0
          if(finds.value) return Promise.resolve({average:finds.moving_avg, balance:finds.balance, qty_suggest: finds.qty_suggest||0})
          else return Promise.resolve({average:0,balance:0,qty_suggest:0})
        } else {
          return Promise.resolve({average:0,balance:0,qty_suggest:0})
        }
      } catch (error) {
        return Promise.resolve({average:0,balance:0,qty_suggest:0})
      }
    },
    fetchGroup(){
      const details = this.details
      const vm = this
      Promise.all(details.map((n,i) => vm.rowFindStockReal(i)))
    },
    addRow(){
      let data = JSON.parse( JSON.stringify(this.utils.detailDefault) )
      if(this.formStatus==2){
        data = Object.assign({},this.utils.detailDefault,{
          qty_approve: 1,
          id_unit_approve: null,
          name_unit_approve: ""
        })
      }
      this.details.push(data)
    },
    deleteRow(row){
      this.details.splice(row,1)
    },
    onClickApprove(){
      this.approvalButton = "Disetujui Pusat"
      this.submitForm()
    },
    onClickReject(){
      this.approvalButton = "Ditolak Pusat"
      this.submitForm()
    }
  },
  mounted(){
    this.getData()
  }
}
</script>

<style>

</style>